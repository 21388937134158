<template>
    <div id="users">
        <a-page-header style="border-bottom: 2px solid rgb(235, 237, 240); margin-bottom: 10px;" title="管理员管理" sub-title="查看并修改管理员信息" />
        <a-modal v-model:visible="showEdit" title="编辑管理员信息" @ok="editOK" @cancel="editCancel" okText="确认" cancelText="取消">
            <a-row class="rows">
                <a-col span="8" class="center">权限:</a-col>
                <a-col span="16">
                    <a-cascader v-model:value="editPower" :options="powerOptions" :allowClear="false" placeholder="" @change="" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="8" class="center" v-if="editPower == 1">分管领域:</a-col>
                <a-col span="16">
                    <a-cascader v-model:value="editRegion" :options="regionOptions" :allowClear="false" placeholder="" @change="" />
                </a-col>
            </a-row>
        </a-modal>
        <a-modal v-model:visible="showChange" title="修改密码" @ok="changePassOK" @cancel="changePassCancel" okText="确认" cancelText="取消">
            <a-row class="rows">
                <a-col span="8" class="center">请填写旧密码:</a-col>
                <a-col span="16">
                    <a-input-password v-model:value="oldPass" placeholder="" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="8" class="center">请填写新密码:</a-col>
                <a-col span="16">
                    <a-input-password v-model:value="newPass" placeholder="" />
                </a-col>
            </a-row>
        </a-modal>
        <a-modal v-model:visible="showNew" title="添加管理员" @ok="createOK" @cancel="createCancel" okText="确认" cancelText="取消">
            <a-row class="rows">
                <a-col span="8" class="center">请填写账号:</a-col>
                <a-col span="16">
                    <a-input v-model:value="newAccount" placeholder="" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="8" class="center">请填写密码:</a-col>
                <a-col span="16">
                    <a-input-password v-model:value="newCPass" placeholder="" />
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="8" class="center">权限:</a-col>
                <a-col span="16">
                    <a-cascader v-model:value="newPower" :options="powerOptions" :allowClear="false" placeholder="" @change="" />
                </a-col>
            </a-row>
            <a-row class="rows" v-if="newPower == 1">
                <a-col span="8" class="center">分管领域:</a-col>
                <a-col span="16">
                    <a-cascader v-model:value="newRegion" :options="regionOptions" :allowClear="false" placeholder="" @change="" />
                </a-col>
            </a-row>
        </a-modal>
        <a-row>
            <a-col span="2">
                <a-button type="primary" @click="onCreate">添加管理员</a-button>
            </a-col>
        </a-row>
        <a-table :dataSource="users" :columns="cols" :pagination="false" @change="" style="margin-top: 10px;">
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'power'">
                    <span v-if="record.power == 1">管理员</span>
                    <span v-else-if="record.power == 2">超级管理员</span>
                </template>
                <template v-else-if="column.key === 'region'">
                    <span v-if="record.power == 1">{{$store.state.category.category[record.region] ? $store.state.category.category[record.region] : '未分类'}}</span>
                    <span v-else-if="record.power == 2">全部可见</span>
                </template>
                <template v-else-if="column.key === 'action'">
                    <a-button type="primary" @click="edit(record.id)">编辑</a-button>
                    <a-divider type="vertical" />
                    <a-button type="primary" @click="changePass(record.id)">修改密码</a-button>
                    <a-divider type="vertical" />
                    <a-button type="danger" @click="removeAccount(record.id)">移除</a-button>
                </template>
            </template>
        </a-table>
    </div>
</template>

<script>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { ref, onMounted, computed, watch, createVNode , defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { message, Modal } from 'ant-design-vue';
import axios from "axios";
import moment from "moment";
import md5 from "md5";
export default {
    methods: { },
    data () {
        return { };
    },
    setup() {
        const store = useStore();
        const cols = ref([
            {
                title: '账户',
                dataIndex: 'account',
                key: 'account'
            },
            {
                title: '权限',
                dataIndex: 'power',
                key: 'power'
            },
            {
                title: '分管领域',
                dataIndex: 'region',
                key: 'region'
            },
            {
                title: '操作',
                key: 'action'
            }
        ]);

        const users = ref([ ]);
        const categoryMap = ref(['', '食品', '药品', '计量', '电梯', '虚假广告']);
        const powerOptions = ref([
            {
                value: 1,
                label: '管理员'
            },
            {
                value: 2,
                label: '超级管理员'
            }
        ]);
        const regionOptions = computed(() => {
            var result = [];
            for (let i in store.state.category.category) {
                if (i) {
                    result.push({
                        value: parseInt(i),
                        label: store.state.category.category[i]
                    });
                }
            }
            return result;
        });

        const showEdit = ref(false);
        const editID = ref('');
        const editPower = ref([1]);
        const editRegion = ref(0);

        const showChange = ref(false);
        const passID = ref('');
        const oldPass = ref('');
        const newPass = ref('');

        const showNew = ref(false);
        const newAccount = ref('');
        const newPower = ref([1]);
        const newRegion = ref('');
        const newCPass = ref('');

        function edit(ID) {
            showEdit.value = true;
            editID.value = ID;
            editPower.value = users.value.filter(data => data.ID == ID)[0].power;
            editRegion.value = users.value.filter(data => data.ID == ID)[0].region;
        }

        function editOK() {
            axios
                .post("/api/server/updateAdministrator", {
                    id: editID.value,
                    password: null,
                    power: editPower.value,
                    region: editRegion.value[0]
                })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        message.success('管理员编辑成功');
                        showEdit.value = false;
                        get_users();
                    } else {
                        message.error('未能成功编辑管理员：' + res.data.msg)
                    }
                });
        }

        function editCancel() {
            showEdit.value = false;
            editID.value = '';
            editPower.value = 1;
            editRegion.value = 0;
        }

        function changePass(ID) {
            showChange.value = true;
            passID.value = ID;
        }

        function changePassOK() {
            // axios
            //     .post("/api/server/updateAdministrator", {
            //         id: editID.value,
            //         password: null,
            //         power: editPower.value,
            //         region: editRegion.value[0]
            //     })
            //     .then((res) => {
            //         console.log(res.data);
            //         if (res.data.success) {
            //             message.success('管理员编辑成功');
            //             showEdit.value = false;
            //             get_users();
            //         } else {
            //             message.error('未能成功编辑管理员：' + res.data.msg)
            //         }
            //     });
        }

        function changePassCancel() {
            showChange.value = false;
            passID.value = '';
            oldPass.value = '';
            newPass.value = '';
        }

        function onCreate() {
            newAccount.value = '';
            newPower.value = [1];
            newRegion.value = [];
            newCPass.value = '';
            showNew.value = true;
        }

        function createOK() {
            console.log(newPower.value)
            axios
                .post("/api/server/addAdministrator", {
                    account: newAccount.value,
                    password: md5(newCPass.value),
                    power: newPower.value[0],
                    region: newPower.value[0] == 2 ? 0 : newRegion.value[0]
                })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        message.success('管理员添加成功');
                        showNew.value = false;
                        get_users();
                    } else {
                        message.error('未能成功添加管理员：' + res.data.msg)
                    }
                });
        }

        function createCancel() {
            newAccount.value = '';
            newPower.value = 1;
            newRegion.value = 1;
            newCPass.value = '';
            showNew.value = false;
        }

        function removeAccount(ID) {
            Modal.confirm({
                title: '移除账号',
                icon: createVNode(ExclamationCircleOutlined),
                content: 'B确认要移除这个账号吗？该操作不能撤销',
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    axios
                        .post("/api/server/deleteAdministrator", {
                            id: ID
                        })
                        .then((res) => {
                            console.log(res.data);
                            if (res.data.success) {
                                message.success('管理员移除成功');
                                get_users();
                            } else {
                                message.error('未能成功移除管理员：' + res.data.msg)
                            }
                        });
                }
            });
        }

        function get_users() {
            axios
                .post("/api/server/getAdministrators")
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        users.value = res.data.data;
                    } else {
                        message.error('无法获取管理员信息：' + res.data.msg);
                    }
                });
        }

        onMounted(() => {
            store.dispatch({ type: 'setSelectedKeys', selectedKeys: ['Users'] });
            store.dispatch({ type: 'setOpenKeys', openKeys: ['Settings'] });
            get_users();
        });

        return {
            cols,
            users,
            categoryMap,
            powerOptions,
            regionOptions,
            showEdit,
            editID,
            editPower,
            editRegion,
            showChange,
            passID,
            oldPass,
            newPass,
            showNew,
            newAccount,
            newPower,
            newRegion,
            newCPass,
            edit,
            editOK,
            editCancel,
            changePass,
            changePassOK,
            changePassCancel,
            onCreate,
            createOK,
            createCancel,
            removeAccount,
            get_users
        }
    }
}
</script>

<style>
#users {
    width: 100%;
    text-align: left;
}

.rows {
    margin-bottom: 10px;
    border: none;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
